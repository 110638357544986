<template>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                <i class="fa fa fa-paypal animated bounceInDown show-info"></i>
                    Direct Payment
                </h3>
            </div>
            <div class="col-md-6 ">
                <div class="row">
                    <div class="col-md-10" >
                        <div class="row" style="padding-top:18px;justify-content:flex-end;">
                            <div  class="col-md-2" style="margin-right:25px;">             
                                <button @click="refresh()" class="btn btn-primary" ><i class="fa fa-refresh"></i>  Refresh</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div class="col-md-12">
        <div class="panel panel-cascade">
            <div class="info-box bg-warning text-white">
                <div class="info-icon bg-primary-dark" style="width:30% ;">
                    <h4>
                        <i class="fa fa-user"></i>
                        Searh LAN Number
                    </h4>
                </div>
            </div>
            <div class="panel-body">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>Enter LAN : </td>
                            <td><input class="form-control form-cascade-control input-smal input-small" v-model="searchstring" type="text" style="background-color: rgb(255, 255, 255);"></td>
                            <td>
                                <button type="submit" @click="search()" class="btn bg-primary text-white btn-md">Search</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="panel panel-cascade pt-20" v-if="edititem!=null">
            <div class="info-icon bg-sheme-dark" style="padding:5px;">
                <h5 style="margin-left:20px"> <i class="fa fa fa-user" style="font-size:20px"></i>   Customer Details</h5>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="panel panel-cascade" style="padding-bottom:20px" v-if="edititem!=null">
            <div class="panel-body">
                <div class="form-horizontal cascde-forms">
                    <table class="table table-bordered ">
                            <tbody class="table-font">
                                <tr>
                                    <td class="mw-30" style="padding-bottom:0px;width:25%;">
                                        <div class="text-left text">Photo :</div>
                                            <div class="text1"><img :src="profileimageurl" width="50px" height="50px"/></div>
                                    </td>
                                    <td class="mw-30">
                                        <div class="text-left text">NAME:</div>
                                            <div class="text1" v-if="edititem.customer!=null">{{ edititem.customer.name }}</div>
                                    </td>
                                    <td >
                                        <div class="text-left text ">Mobile No:</div>
                                            <div class="text1" v-if="edititem.customer!=null"><display-mobiles :mobiles="edititem.customer.mobiles"></display-mobiles></div>
                                    </td>
                                    <td style="padding-bottom:0px">
                                        <div class="text-left text ">Introducer:</div>
                                        <div class="text1" v-if="edititem.customer!=null">
                                            <template v-if="edititem.customer.referraluser!=null">
                                            {{ edititem.customer.referraluser.name }}
                                            {{ edititem.customer.referraluser.generatedid }}
                                            <display-mobiles :mobiles="edititem.customer.referraluser.mobiles"></display-mobiles>
                                        </template>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:20%;">
                                        <div class="text-left text ">Address:</div>
                                        <div class="text1" v-if="edititem.customer!=null"><display-first-address :addresses="edititem.customer.addressess"></display-first-address></div>
                                    </td>
                                    <td >
                                        <div class="text-left text ">Landmark:</div>
                                        <div class="text1" >None</div>
                                    </td>
                                    <td >
                                        <div class="text-left text ">Sale By:</div>
                                        <div class="text1" ><template v-if="edititem.saletransaction!=null">{{edititem.saletransaction.saleby.name}}</template></div>
                                    </td>
                                    <td >
                                        <div class="text-left text ">Customer Type:</div>
                                        <div class="text1" v-if="edititem.customer!=null"><template v-if="edititem.customer.customer_type!=null">{{edititem.customer.customer_type}}</template></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">
                                        <div class="text-left text ">First Document:</div>
                                        <div class="text1">
                                            <display-id-proof-front-back  :labelid="1" frontorback="1"></display-id-proof-front-back>     
                                    </div>

                                    </td>
                                    <td>
                                        <div class="text-left text ">Second Document:</div>
                                        <div class="text1">
                                            <display-id-proof-front-back  :labelid="2" frontorback="1"></display-id-proof-front-back>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-left text ">Third Document:</div>
                                        <div class="text1 ">
                                            <display-id-proof-front-back :labelid="3" frontorback="1"></display-id-proof-front-back>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-left text ">Fourth Document:</div>
                                        <div class="text1 ">
                                            <display-id-proof-front-back :labelid="4" frontorback="1"></display-id-proof-front-back>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">
                                        <div class="text-left text ">Zone:</div>
                                        <div class="text1" v-if="edititem.customer!=null">{{ getMasterLabelByType(edititem.customer.zoneid,MASTER_ZONE_TYPES) }}</div>
                                    </td>
                                
                                    <td >
                                        <div class="text-left text ">Entry Date:</div>
                                        <div class="text1" >
                                            <template v-if="edititem.saletransaction!=null">
                                                <i class='bx bx-calendar-alt'></i>  {{moment(edititem.saletransaction.created_at).format("DD-MM-YYYY")}}<br>
                                                <i class='bx bx-time-five'></i> {{moment(edititem.saletransaction.created_at).format("h:mm:ss A")}}
                                            </template>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="info-box  bg-sheme  text-white" v-if="edititem!=null">
        <div class="info-icon bg-sheme-dark" style="padding:5px;">
            <h5><i class="fa fa fa-user" style="font-size:25px"></i>  Emi Details</h5>
        </div>
    </div>
    <div class="panel panel-cascade" v-if="edititem!=null">
        <div class="panel-body">
            <div class="form-horizontal cascde-forms">
                <table class="table table-sm table-hover table-inverse table-striped display">
                    <thead class="table-font">
                        <tr>
                            <th>#</th>
                            <th>EMI NO</th>
                            <th>Emi Amount</th>
                            <th>Rec. Amount</th>
                            <th>Emi Date</th>
                            <th>Received Date</th>
                            <th>Receipt No</th>
                            <th>Collected By</th>
                            <th>Entry Date</th>
                            <th>Message</th>
                            <th>Entry By</th> 
                        </tr>
                    </thead>
                    <!-- <loan-emi-row v-for="(item,index) in edititem.emis" :index="index" v-bind:key="item.id" :emiid="item.id"></loan-emi-row> -->
                    <template v-if="edititem!=null && edititem.emis!=null">
                        <tbody class="table-font" v-for="(item,index) in singleemi.slice(0,1)" :key="item.id">
                            <tr>
                                <td>{{index+1}}</td>
                                <td>{{ item.id }}</td>
                                <td><i class='bx bx-rupee'></i> {{ item.tobepaid }}</td>
                                <td><i class='bx bx-rupee'></i> {{ item.receviedamount }}</td>
                                <td><i class='bx bx-calendar-alt'></i> {{ moment(item.emidate).format("DD-MM-YYYY") }}</td>
                                
                                <td><i class='bx bx-calendar-alt'></i> {{item.payment!=null?item.payment.transactiondate:''}}</td>
                                <td>{{item.payment!=null?item.payment.receiptno:''}}</td>
                                <td>{{item.payment!=null?getusername(item.payment.collectedbyid):''}}</td>
                                <td><i class='bx bx-calendar-alt'></i>{{item.payment!=null?moment(item.payment.created_at).format("DD-MM-YYYY"):''}}<br>
                                    <i class='bx bx-time-five'></i> {{item.payment!=null?moment(item.payment.created_at).format("h:mm:ss A"):''}}
                                </td>
                                <td><button class="badge bg-sheme"><i class="fa fa-envelope"></i> {{ item.visitcomments.length}}</button></td>
                                <td>{{item.payment!=null?item.payment.entryby!=null?item.payment.entryby.name:'':''}}</td>
                                <td style="padding-left:0px;padding-right:0">
                                    <button v-if="item.paymenttransactionid==0" class="btn btnsearch btn-view" type="button" @click="payemi()" style="background-color:#179b17!important;padding:7px;font-size:9px;border-radius:25px;">
                                        <i v-if="showpaymentform==false" class="fa fa-arrow-up"></i>
                                        <i v-else class="fa fa-arrow-down"></i> Pay Emi
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <button class="btn btnsearch btn-view emi-detail-btn" style="width:50%" type="button" @click="visitcomment(item)">
                                        <i v-if="showvisitcomments==false" class="fa fa-plus"></i>
                                        <i  v-else class="fa fa-minus"></i>  View details
                                    </button>
                                </td>
                            </tr>
                            <tr v-if="showpaymentform">
                                <td colspan="11">
                                    <loan-emi-payment-form :emiitem="item"></loan-emi-payment-form>
                                </td>
                            </tr>
                            <tr v-if="showvisitcomments">
                                <td colspan="11">
                                    <loan-emi-visit-comment :emiid="item.id" :emi="item" :commentlist="commentlist"></loan-emi-visit-comment> 
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import DisplayIdProofFrontBack from '../../components/idproof/DisplayIdProofFrontBack.vue'
import moment from 'moment'
import LoanEmiPaymentForm from '../../components/loanemi/LoanEmiPaymentForm.vue'
import LoanEmiVisitComment from '../../components/loanemi/LoanEmiVisitComment.vue'
export default {
    mixins:[Constants],
    components :{DisplayMobiles,DisplayFirstAddress,
    LoanEmiPaymentForm,LoanEmiVisitComment,
    DisplayIdProofFrontBack},
    data(){
        return {
            searchstring:'',
            moment:moment,
            showpaymentform:false,
            showvisitcomments:false,
            paymentid:1,
            commentlist:[]
        }
    },
    computed:{
        ...mapGetters([
            'edititem'
        ]),
        profileimageurl(){
            if(this.edititem!=null){
                if(this.edititem.profileimage!=null){
                    return this.edititem.profileimage.fullurl;
                }
            }
            return '#'
        },
        singleemi(){
            if(this.edititem!=null && this.edititem.emis!=null){
                //console.log(this.edititem.emis)
                let oneemi=[];
                this.edititem.emis.filter(block=>{
                    if(block.payment==null){
                        oneemi.push(block)
                    }else{
                        return ''
                    }
                })
                //console.log('emi',oneemi)
                return oneemi
            }
            return ''
        },
        
    },
    mounted() {
        this.$store.commit('assignedititem',null)
    },
    methods:{
        search(){
            let param = {searchstring:this.searchstring}
            this.$http.post('api/saletransactions/datewise',param)
            .then((response) => this.processResponse1(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processResponse1(data){

            let param={id:data[0].loandetails.id}
            this.$http.post('api/loan/details',param)
            .then((response) => this.processResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processResponse(data){
            this.$store.commit("assignedititem",data)
        },
        payemi(){
            this.showpaymentform = !this.showpaymentform
            //this.$store.commit('assignedititem2',this.item)
        },
        visitcomment(item){
            let param = {emiid:item.id}
            this.$http.post('api/loan/emi/visitcomments/fetch',param)
            .then((response) => this.processDetailResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
            this.showvisitcomments = !this.showvisitcomments;
        },
        processDetailResponse(data){

            this.$store.commit('assignlist',data)
        },
        refresh(){
            this.searchstring=''
            this.$store.commit("assignedititem",null)
        },
       
    }
}
</script>